import React, { useEffect } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphones, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../../assets/Logo2.PNG';
import NavigationBar from '../Navbar/NavigationBar';

const Contact = () => {
  useEffect(() => {
    // Load HubSpot form script only if it hasn't been loaded yet
    const existingScript = document.getElementById('hubspot-form-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.charset = 'utf-8';
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.id = 'hubspot-form-script'; // Add an ID to ensure it's only added once
      script.onload = () => {
        if (window.hbspt && window.hbspt.forms) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "47276595",
            formId: "73c674ef-a155-42ff-93eb-bb9fe0ad5824",
            target: '#hubspot-form-container' // Specify the container where the form should be embedded
          });
        }
      };
      document.body.appendChild(script);

      // Cleanup script on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="page-wrapper"> 
        <NavigationBar/>
        <div className="section-padding">
          <div className="center-content-general">
            <div className='logo-image-box'>
              <img
                  src={logoImage}
                  alt="Logo"
                  className="logo-image"
              />
            </div>
            <h1 className="gradient-text-medium">Get in Touch With Us!</h1>

            <hr className="small-separator" />
            <h1 className='white-text-small-medium'>We would love to hear from you.</h1>                  
            <hr className="medium-separator" />
          </div>

          {/*----------------- CARDS--------------- */}
          <div className="card-container-contact">
            <div className="card-contact">
              <div className="card-text-contact">
                <FontAwesomeIcon icon={faHeadphones} className="card-icon-contact" />
                <p className='first-text-card'>Phone</p>
                <p className='second-text-card'>+1 508 203 6919</p>
              </div>
            </div>

            <div className="card-contact">
              <div className="card-text-contact">
                <FontAwesomeIcon icon={faEnvelope} className="card-icon-contact" />
                <p className='first-text-card'>Email</p>
                <p className='second-text-card'>admin@rosewood-advisors.com</p>
              </div>
            </div>

            <div className="card-contact">
              <div className="card-text-contact">
                <FontAwesomeIcon icon={faLocationDot} className="card-icon-contact" />
                <p className='first-text-card'>Location</p>
                <p className='second-text-card'>We're everywhere, so you don't have to be.</p>
              </div>
            </div>
          </div>

          {/*----------------- HUBSPOT FORM--------------- */}
          <div id="hubspot-form-container" className="hubspot-form-container">
            {/* The HubSpot form will be dynamically inserted here */}
          </div>
        </div>
    </div>
  );
}

export default Contact;
