import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/Logo2.PNG';
import NavigationBar from '../Navbar/NavigationBar';
import '../../assets/styles/generalStyle.css';

const LandingPage = () => {

  return (
    <div className="page-wrapper">
      <NavigationBar/>

      <div className="header-container section-padding">
          <div className="logo-image-box">
            <img
              src={logoImage}
              alt="Logo"
              className="logo-image"
              />
          </div>
          <div className="main-header">
            <div classname="rosewood-header">
              <h1 className="gradient-text-large">Rosewood Advisors</h1>
              {/* <h1 className="white-text-large">Business Made Simple.</h1> */}
            </div>  
          </div>

          <hr class="small-separator"></hr>
          
          <div className="footer-header">
            <p className="white-text-medium">Advisory for startups and small business — simplified.</p>
          </div>
          
          <hr class="small-separator"></hr>
          <hr class="small-separator"></hr>
          
          <Link to="/overview" className="cta1" style={{ textDecoration: 'none' }}>
            Learn More
          </Link>
      </div>
      {/* <div className="elipse-top-right">

      </div> */}
      <div>

      </div>
    </div>
  );
}

export default LandingPage;
