import React, { useEffect, useState } from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationBar from '../Navbar/NavigationBar';
import { faChartLine, faChessPawn, faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/generalStyle.css';

const Overview = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const words = ['Accounting.', 'Finance.', 'Strategy', 'Marketing.', 'Product.', 'Fundraising.'];
    const words2 = ['Honesty.', 'Respect.', 'Humility.', 'Patience.', 'Trust.', 'Camaraderie.', 'Integrity.', 'Kindness.']
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 4000);
  
      return () => clearInterval(interval);
    }, [words.length]);

   
    const [expandedCardIndex, setExpandedCardIndex] = useState(-1);

    const toggleCardExpansion = (index) => {
        if (expandedCardIndex === index) {
            setExpandedCardIndex(-1);
        } else {
            setExpandedCardIndex(index);
        }
    };
  
    return (
        <div className="page-wrapper">
            <NavigationBar/>

            <div className="section-padding">  

                <h1 className='gradient-text-medium-large'>Services for small business.</h1>
                <div className="footer-header">
                        <p className="white-text-small">
                            Do what you're passionate about - we'll handle the rest.
                        </p>
                </div>
                <hr className="medium-separator"></hr>                    
                <div className='card-container'>
                
                    <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChessPawn} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Accounting</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">Accounting is the language of business; keep a pulse on your company with actionable financial statements and analytics.</div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Finance</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">Working capital management, budgeting, modeling, board reporting, lender reporting, we've got you covered.</div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faMagnifyingGlassDollar} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Data Analytics </h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">Operational metrics drive efficiency and customer satisfaction, while financial metrics drive profitability and business sustainability.</div>
                        </div>
                    </div>
                    
                    <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Marketing</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">Grow with customer acquisition done right — profitable, consistent, and aligned with your financial goals.</div>
                        </div>
                    </div>

                    {/* <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Strategy</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">This is where a sentence or two of description will go. Remember, this is a marketing page, not a fact sheet.</div>
                        </div>
                    </div> */}

                    {/* <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Software Development</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">This is where a sentence or two of description will go. Remember, this is a marketing page, not a fact sheet.</div>
                        </div>
                    </div> */}


                    {/* <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Fundraising & Capital</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">This is where a sentence or two of description will go. Remember, this is a marketing page, not a fact sheet.</div>
                        </div>
                    </div> */}

                    {/* <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>New Venture Development</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">This is where a sentence or two of description will go. Remember, this is a marketing page, not a fact sheet.</div>
                        </div>
                    </div> */}

                    {/* <div className='card'>
                        <div className="card-row">
                            <FontAwesomeIcon icon={faChartLine} className="card-icon" />
                        </div>
                        <div className="card-row">
                            <h1 className='white-text-medium'>Technology Management</h1>
                        </div>
                        <div className="card-row">
                            <div className="small-text">This is where a sentence or two of description will go. Remember, this is a marketing page, not a fact sheet.</div>
                        </div>
                    </div> */}

                </div>
            </div>


            
            <NavigationBar/>
        </div>
    );
  };
  
  export default Overview;