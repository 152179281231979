import React from 'react';
import './assets/styles/generalStyle.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import NavigationBar from './components/Navbar/NavigationBar';
import LandingPage from './components/LandingPage/LandingPage';
import Overview from './components/Overview/Overview';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <Router>
      <div>
        {/* <NavigationBar /> */}

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/overview" element={<Overview />} />
          <Route path = "/services" element = {<Services/>}/>
          <Route path = "/contact" element = {<Contact/>}/>
        </Routes>
      </div>
      <hr/>
    </Router>
  );
}

export default App;
